import React from "react";
import styled from "styled-components";
import { Tag } from "./Tag";

const StyledContainer = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  height: 100%;
`;

export const Tags = ({ className, items, path }) => {
  if (!items) {
    return null;
  }
  return (
    <StyledContainer className={className}>
      {items.map((item) => (
        <Tag text={item} path={path || `/tag/${item}`} key={item} />
      ))}
    </StyledContainer>
  );
};
